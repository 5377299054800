<template>
    <div class="contract">
        <div class="row mb-4">
            <div class="d-flex align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <ContentLoader width="20" height="20">
                    <rect x="0" y="0" width="20" height="20" rx="10" ry="10" />
                </ContentLoader>
                <span class="h5 contract-head ms-2 mb-0">
                    <ContentLoader width="200" height="20">
                        <rect x="0" y="0" width="200" height="20" rx="10" ry="10" />
                    </ContentLoader>
                </span>
            </div>
            <div class="d-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 justify-content-end">
                <div class="me-3">
                    <ContentLoader width="100" height="30" primary-color="#B4B7BF" secondary-color="#D4D6DA">
                        <rect x="0" y="0" width="100" height="30" rx="10" ry="10" />
                    </ContentLoader>
                </div>
                <div>
                    <ContentLoader width="100" height="30" primary-color="#B4B7BF" secondary-color="#D4D6DA">
                        <rect x="0" y="0" width="100" height="30" rx="10" ry="10" />
                    </ContentLoader>
                </div>
            </div>
        </div>
        <div class="contract-items">
            <p class="
       contract-item-head
      ">
                <ContentLoader width="130" height="20">
                    <rect x="0" y="0" width="130" height="20" rx="10" ry="10" />
                </ContentLoader>
            </p>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row mt-4">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                </div>
            </div>

        </div>

        <div class="contract-items mt-5">
            <p class="
       contract-item-head
      ">
                <ContentLoader width="130" height="20">
                    <rect x="0" y="0" width="130" height="20" rx="10" ry="10" />
                </ContentLoader>
            </p>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row mt-4">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                </div>
            </div>
        </div>


        <div class="contract-items mt-5">
            <p class="
       contract-item-head
      ">
                <ContentLoader width="130" height="20">
                    <rect x="0" y="0" width="130" height="20" rx="10" ry="10" />
                </ContentLoader>
            </p>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row mt-4">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
                        <ContentLoader width="130" height="50">
                            <rect x="0" y="0" width="130" height="50" rx="25" ry="25" />
                        </ContentLoader>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end  pt-2 mt-3">
            <div class="d-flex align-items-center  contract-footer">
                <ContentLoader width="100" height="30" primary-color="#B4B7BF" secondary-color="#D4D6DA">
                    <rect x="0" y="0" width="100" height="30" rx="10" ry="10" />
                </ContentLoader>
            </div>
        </div>
    </div>
</template>
  